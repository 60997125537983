import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';
import './AppWorks.css';  // Import the CSS file

const worksData = [
  {
    id: 1,
    image: require('../assets/images/bangladesh.png'),
    titleKey: 'works.countries.bangladesh',
    subtitleKey: 'works.web_design',
    link: 'https://www.mfa.gov.tr/banglades-kunyesi.tr.mfa'
  },
  {
    id: 2,
    image: require('../assets/images/egypt.png'),
    titleKey: 'works.countries.egypt',
    subtitleKey: 'works.branding',
    link: 'https://www.mfa.gov.tr/misir-kunyesi.tr.mfa'
  },
  {
    id: 3,
    image: require('../assets/images/indonesia.png'),
    titleKey: 'works.countries.indonesia',
    subtitleKey: 'works.web_design',
    link: 'https://www.mfa.gov.tr/endonezya-kunyesi.tr.mfa'
  },
  {
    id: 4,
    image: require('../assets/images/iran.png'),
    titleKey: 'works.countries.iran',
    subtitleKey: 'works.branding',
    link: 'https://www.mfa.gov.tr/iran-kunyesi.tr.mfa'
  },
  {
    id: 5,
    image: require('../assets/images/malaysia.png'),
    titleKey: 'works.countries.malaysia',
    subtitleKey: 'works.photography',
    link: 'https://www.mfa.gov.tr/malezya-kunyesi.tr.mfa'
  },
  {
    id: 6,
    image: require('../assets/images/nigeria.png'),
    titleKey: 'works.countries.nigeria',
    subtitleKey: 'works.web_design',
    link: 'https://www.mfa.gov.tr/nijerya-kunyesi.tr.mfa'
  },
  {
    id: 7,
    image: require('../assets/images/pakistan.png'),
    titleKey: 'works.countries.pakistan',
    subtitleKey: 'works.web_design',
    link: 'https://www.mfa.gov.tr/pakistan-kunyesi.tr.mfa'
  },
  {
    id: 8,
    image: require('../assets/images/turkey.png'),
    titleKey: 'works.countries.turkey',
    subtitleKey: 'works.web_design',
    link: 'https://www.mfa.gov.tr/turkey-kunyesi.tr.mfa'
  },
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

function AppWorks2() {
  const { t } = useTranslation("global"); // Use the useTranslation hook

  return (
    <section id="works2" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2 style={{ fontWeight: 'bolder' }}>{t('works.title')}</h2>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <a href={works.link} target="_blank" rel="noopener noreferrer">
                    <div className='portfolio-wrapper'>
                      <Image src={works.image} />
                      <div className='label text-center'>
                        <h3>{t(works.titleKey)}</h3>
                        <p>{t(works.subtitleKey)}</p>
                      </div>
                    </div>
                  </a>
                </Col>
              );
            })
          }
        </Row>
      </Container>  
    </section>
  );
}

export default AppWorks2;
