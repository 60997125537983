import React from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import './AppTestimonials.css';  // Import the CSS file
import { useTranslation } from 'react-i18next';

const testimonialsData = [
  {
    id: 1,
    titleKey: 'activities.items.0.title',
    descriptionKeys: [
      'activities.items.0.description.0',
      'activities.items.0.description.1',
      'activities.items.0.description.2',
    ]
  },
  {
    id: 2,
    titleKey: 'activities.items.1.title',
    descriptionKeys: [
      'activities.items.1.description.0',
      'activities.items.1.description.1',
      'activities.items.1.description.2',
      'activities.items.1.description.3',
    
    ]
  },
  {
    id: 3,
    titleKey: 'activities.items.2.title',
    descriptionKeys: [
      'activities.items.2.description.0',
      'activities.items.2.description.1',
      'activities.items.2.description.2',
    ]
  },
  {
    id: 4,
    titleKey: 'activities.items.3.title',
    descriptionKeys: [
      'activities.items.3.description.0',
      'activities.items.3.description.1',
      'activities.items.3.description.2',
    ]
  },
  {
    id: 5,
    titleKey: 'activities.items.4.title',
    descriptionKeys: [
      'activities.items.4.description.0',
      'activities.items.4.description.1',
      'activities.items.4.description.2',
    ]
  }
];

function AppTestimonials() {
  const { t } = useTranslation('global');
  return (
    <section id="testimonials" className="testimonials-block">
      <Container fluid>
        <div className="title-holder">
          <h2 style={{ fontWeight: 'bolder' }}>{t('testimonials.title')}</h2>
          <p>{t('testimonials.subtitle')}</p>
        </div>
        <Carousel controls={false}>
          {
            testimonialsData.map(testimonial => {
              return (
                <Carousel.Item key={testimonial.id}>
                  <blockquote>
                    <cite>
                      <h3>{t(testimonial.titleKey)}</h3>
                    </cite>
                    <ul>
                      {testimonial.descriptionKeys.map((key, index) => (
                        <li key={index}>{t(key)}</li>
                      ))}
                    </ul>
                  </blockquote>             
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTestimonials;
