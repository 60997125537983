import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-bullseye',
    titleKey: 'services.items.0.title',
    descriptionKeys: [
      'services.items.0.description.0',
      'services.items.0.description.1',
      'services.items.0.description.2',
      'services.items.0.description.3'
    ]
  },
  {
    id: 2,
    icon: 'fas fa-tasks',
    titleKey: 'services.items.1.title',
    descriptionKeys: [
      'services.items.1.description.0',
      'services.items.1.description.1',
      'services.items.1.description.2',
      'services.items.1.description.3'
    ]
  },
  {
    id: 3,
    icon: 'fas fa-trophy',
    titleKey: 'services.items.2.title',
    descriptionKeys: [
      'services.items.2.description.0',
      'services.items.2.description.1',
      'services.items.2.description.2',
      'services.items.2.description.3',
      'services.items.2.description.4'
    ]
  }
];

function AppServices() {
  const { t } = useTranslation('global');

  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2 style={{ fontWeight: 'bolder' }}>{t('services.title')}</h2>
          <div className="subtitle">{t('services.subtitle')}</div>
        </div>
        <Row>
          {servicesData.map(service => (
            <Col sm={4} className='holder' key={service.id}>
              <div className="icon">
                <i className={service.icon}></i>
              </div>
              <h3>{t(service.titleKey)}</h3>
              <ul>
                {service.descriptionKeys.map((key, index) => (
                  <li key={index}>● {t(key)}</li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;
