import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player';
import './AppAbout.css'; // Import the CSS file

function AppAbout() {
  const { t } = useTranslation("global");

  return (
    <section id="about2" className="block about-block">
      <Container fluid>
        <h2 className="section-title">{t('importance.title')}</h2>
        <Row>
          <Col md={6} className="text-section">
            <ul className="points-list">
              {t('importance.points', { returnObjects: true }).map((point, index) => (
                <li key={index}>● {point}</li>
              ))}
            </ul>
            <br />
            <h3 className="subsection-title">{t('importance.turkey_title')}</h3>
            <ul className="points-list">
              {t('importance.turkey_points', { returnObjects: true }).map((point, index) => (
                <li key={index}>● {point}</li>
              ))}
            </ul>
          </Col>
          
        </Row>
      </Container>
      <Col md={6} className="video-section">
            <div className="video-grid">
              <ReactPlayer url="https://youtu.be/kKz50wsGE0c?si=IaKQvPI8Y3xIEpxk" className="video-player" />
              <ReactPlayer url="https://youtu.be/ZTiETHhBySU?si=qu1oCpC_oRL5I3ZS" className="video-player" />
              <ReactPlayer url="https://youtu.be/Zcphob5OiB0?si=FYU-NpYC2rekKOkZ" className="video-player" />
              <ReactPlayer url="https://youtu.be/PGphPQvvYdA?si=Q_9DYEK5-tW1eQM2" className="video-player" />
              <ReactPlayer url="https://youtu.be/Q-Wt6x55kSU?si=dQDzAcqBVj2DHVHx" className="video-player" />
              <ReactPlayer url="https://youtu.be/T1emH7zFFB4?si=JeCEWZl12n2rYZEU" className="video-player" />
              <ReactPlayer url="https://youtu.be/S0IDHw09tcU?si=T37luFK0IgZzVFqT" className="video-player" />
              <ReactPlayer url="https://youtu.be/Fe7d5NjOKx4?si=91QTKLk0GP9Gl82n" className="video-player" />
            </div>
          </Col>
    </section>
  );
}

export default AppAbout;
