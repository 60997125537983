import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './AppHeader.css';
import logo from '../assets/logod8.jpg'; // Import the logo image

function AppHeader() {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" className="logo" /> {/* Add the logo image */}
         D8-BPP
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">{t('header.home')}</Nav.Link>
            <Nav.Link href="#about">{t('header.about')}</Nav.Link>
            <Nav.Link href="#works2">{t('header.countries')}</Nav.Link>
            <Nav.Link href="#works">{t('header.activities')}</Nav.Link>
            <Nav.Link href="#about2">{t('header.importance')}</Nav.Link>
            <Nav.Link href="#services">{t('header.about_us')}</Nav.Link>
            <Nav.Link href="#testimonials">{t('header.events')}</Nav.Link>
            <Nav.Link href="#teams">{t('header.management')}</Nav.Link>
            <Nav.Link href="#contact">{t('header.contact')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <div className="language-switcher">
        <button className="language-button" onClick={() => handleChangeLanguage("en")}>EN</button>
        <button className="language-button" onClick={() => handleChangeLanguage("tr")}>TR</button>
      </div>
    </Navbar>
  );
}

export default AppHeader;
