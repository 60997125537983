import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import hasanAli from '../assets/hasanali.jpg';
import suleyman from '../assets/images/suleyman.jpeg';
import hasan from '../assets/hasa.jpg';
import fatih from '../assets/fatih.jpg';

// Define a constant for the fixed size
const imageSize = {
  width: '353px', // Adjust as needed
  height: '373px', // Adjust as needed
  display: 'block',
  marginBottom: '15px' // Adds space between the image and the button
};

function AppTeams() {
  const { t } = useTranslation("global");
  const teamMembers = [
    {
      image: hasanAli,
      cv: '/'
    },
    {
      image: suleyman,
      cv: '/suleyman-cv.pdf'
    },
    {
      image: hasan,
      cv: '/hasan-cv.pdf'
    },
    {
      image: fatih,
      cv: '/fatih-cv.pdf'
    },
    // Add other team members as needed
  ];

  const members = t('teams.members', { returnObjects: true });

  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2 style={{ fontWeight: 'bolder' }}>{t('teams.title')}</h2>
          <div className="subtitle">{t('teams.subtitle')}</div>
        </div>
        <Row>
          {
            teamMembers.map((member, index) => (
              <Col sm={4} key={index}>
                <div className='content'>
                  
                  <img src={member.image} alt={members[index].name} style={imageSize} />
                  <h3>{members[index].name}</h3>
                  <h5>{members[index].designation}</h5>
                  <Button variant="primary" href={member.cv} target="_blank" rel="noopener noreferrer">
                    CV
                  </Button>
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppTeams;
