import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation once
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AppAbout() {
  const [t, i18n] = useTranslation("global"); // Use the useTranslation hook

  const handleChangeLanguage = (lang) =>{
    i18n.changeLanguage(lang);
  }


  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <Row>
          <Col>
      
            <h2 style={{ fontWeight: 'bolder' }}>{t('about.title')}</h2>
            <p>{t('about.description')}</p>
            <br />
            <h3 style={{ fontWeight: 'bolder' }}>{t('about.objectives')}</h3>
            <ul>
              <li>● {t('about.objective1')}</li>
              <li>● {t('about.objective2')}</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;
