import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';
import './AppWorks.css';  // Import the CSS file

const worksData = [
  {
    id: 1,
    image: require('../assets/images/farm.jpg'),
    titleKey: 'works2.items.agriculture',
    subtitle: 'Web Design'
  },
  {
    id: 2,
    image: require('../assets/images/trade.jpg'),
    titleKey: 'works2.items.trade',
    subtitle: 'Branding'
  },
  {
    id: 3,
    image: require('../assets/images/transportion.jpg'),
    titleKey: 'works2.items.transportation',
    subtitle: 'Web Design'
  },
  {
    id: 4,
    image: require('../assets/images/energy.jpg'),
    titleKey: 'works2.items.energy',
    subtitle: 'Branding'
  },
  {
    id: 5,
    image: require('../assets/images/health.jpg'),
    titleKey: 'works2.items.health',
    subtitle: 'Photography'
  },
  {
    id: 6,
    image: require('../assets/images/tourism.jpg'),
    titleKey: 'works2.items.tourism',
    subtitle: 'Web Design'
  },
  {
    id: 7,
    image: require('../assets/images/ai.jpg'),
    titleKey: 'works2.items.ai',
    subtitle: 'Web Design'
  },
  {
    id: 8,
    image: require('../assets/images/defence.jpg'),
    titleKey: 'works2.items.defense',
    subtitle: 'Web Design'
  },
  {
    id: 9,
    image: require('../assets/images/raw.jpg'),
    titleKey: 'works2.items.raw_materials',
    subtitle: 'Web Design'
  },
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

function AppWorks() {
  const { t } = useTranslation("global"); // Use the useTranslation hook

  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2 style={{ fontWeight: 'bolder' }}>{t('works2.title')}</h2>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works2 => {
              return (
                <Col sm={4} key={works2.id}>
                  <div className='portfolio-wrapper'>
                    <Image src={works2.image} />
                    <div className='label text-center'>
                      <h3>{t(works2.titleKey)}</h3>
                    </div>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>  
    </section>
  );
}

export default AppWorks;
