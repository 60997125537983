import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about';
import AppServices from './components/services';
import AppWorks from './components/works';
import AppWorks2 from './components/works2';
import AppTeams from './components/teams';
import AppTestimonials from './components/testimonials';
import AppAbout2 from './components/about2';
import AppBlog from './components/blog';
import AppContact from './components/contact';
import AppFooter from './components/footer';
import global_en from "./translations/en/global.json"
import global_tr from "./translations/tr/global.json"
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation:{escapeValue:false},
  lng:"en",
  resources:{
    en:{
      global:global_en
    },
    tr:{
      global:global_tr
    }
  }
})
function App() {
  return (
    
    <div className="App">
      <I18nextProvider i18n={i18next}>
      <header id='header'>
        <AppHeader />
      </header>
      <main>
        <AppHero />
        <AppAbout />
        <AppWorks2 />
        <AppWorks />
        <AppAbout2 />
        <AppServices />
        <AppTestimonials />
        <AppTeams />
        <AppContact />
      </main>
      <footer id="footer">
        <AppFooter />
      </footer>
      </I18nextProvider>
    </div>
   
  );
}

export default App;
