import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AppContact() {
  const { t } = useTranslation('global');

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>{t('contact.title')}</h2>
          <div className="subtitle">{t('contact.subtitle')}</div>
        </div>
        <Form className='contact-form'>
          <Row>
            <Col sm={4}>
              <Form.Control type="text" placeholder={t('contact.namePlaceholder')} required />
            </Col>
            <Col sm={4}>
              <Form.Control type="email" placeholder={t('contact.emailPlaceholder')} required />
            </Col>
            <Col sm={4}>
              <Form.Control type="tel" placeholder={t('contact.phonePlaceholder')} required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control as="textarea" placeholder={t('contact.messagePlaceholder')} required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">{t('contact.submitButton')}</Button>
          </div>
        </Form>
      </Container>
      <div className='google-map'>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12056.533424845457!2d29.0945975!3d41.0246149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac80bdb44a7ab%3A0x7582e8b8d667b5d7!2s%C3%87akmak%2C%20Poligon%20Cd.%20No%3A1%2C%2034774%20%C3%9Cmraniye%2F%C4%B0stanbul!5e0!3m2!1sen!2str!4v1689033931264!5m2!1sen!2str"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              {t('contact.email')}
            </li>
            <li>
              <i className="fas fa-phone"></i>
              {t('contact.phone')}
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              {t('contact.address')}
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;
