import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import './AppHero.css';

const heroData = [
  {
    id: 1,
    titleKey: 'hero.title',
    descriptionKey: 'hero.description',
    url: "https://developing8.org/news/d-8-secretary-general-ambassador-isiaka-abdulqadir-imam-has-called-on-turkish-businesspeople-to-invest-in-d-8-countries/"
  }
];

function AppHero() {
  const { t } = useTranslation('global');
  const hero = heroData[0];

  return (
    <section id="testimonials" className="hero-block">
      <Container fluid>
        <a href={hero.url} style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="title-holder2">
            <img src={hero.image} alt={hero.alt} className="fit-image" />
            <h3 style={{ fontWeight: 'bolder' }}>{t(hero.titleKey)}</h3>
            <h4>{t(hero.descriptionKey)}</h4>
          </div>
        </a>
      </Container>
    </section>
  );
}

export default AppHero;
